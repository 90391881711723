import React from 'react';

const IconTelegram = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      style={{
        fill: 'white',
        height: '30px',
        width: '28px',
        paddingRight: '4px',
      }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M143.21,498.33c0.49-1.5,0.62-3.27,1.52-4.45c3.76-4.89,7.75-9.7,13.36-12.54c9.42-4.78,19.29-8.51,29.13-12.34
			c7.72-3.01,15.38-6.25,23.1-9.3c9.81-3.88,19.47-8.21,29.4-11.67c9.77-3.41,19.34-7.32,28.94-11.11
			c9.81-3.87,19.72-7.49,29.49-11.47c7.49-3.05,14.89-6.26,22.59-8.81c5.73-1.9,11.11-4.86,16.81-6.88
			c8.05-2.85,15.74-6.57,23.91-9.16c5.75-1.82,11.15-4.74,16.83-6.82c8.01-2.93,15.75-6.55,23.89-9.19
			c5.74-1.87,11.26-4.47,16.84-6.83c7.65-3.24,15.61-5.67,23.21-9.06c5.73-2.55,11.75-4.46,17.53-6.94
			c7.64-3.28,15.61-5.67,23.2-9.09c5.72-2.58,11.7-4.6,17.57-6.84c8.18-3.13,16.35-6.27,24.56-9.31c5.67-2.1,11.07-4.94,16.71-7.13
			c7.95-3.1,16.04-5.83,24.02-8.87c5.45-2.08,10.75-4.56,16.21-6.6c10.73-4,21.32-8.36,32-12.47c7.54-2.9,15.14-5.66,22.62-8.7
			c9.77-3.97,19.41-8.26,29.43-11.63c5.73-1.93,11.19-4.64,16.81-6.89c7.71-3.08,15.47-6.05,23.2-9.08
			c5.85-2.29,11.73-4.52,17.53-6.93c7.67-3.19,15.61-5.67,23.21-9.05c5.74-2.55,11.7-4.59,17.54-6.91
			c7.72-3.06,15.61-5.64,23.19-9.11c5.71-2.61,11.69-4.61,17.56-6.85c8.18-3.12,16.43-6.09,24.53-9.38
			c5.23-2.12,10.44-4.12,15.84-5.74c5.34-1.6,10.69-3.22,16.29-2.8c4.17,0.32,8.24-0.76,12.61,1.18c7.39,3.29,9.76,4.88,12.12,12.36
			c1.76,5.59,1.28,5.52,0.17,13.39c-0.78,5.49-1.91,10.86-3.04,16.27c-0.71,3.39-0.08,7.44-1.74,10.17
			c-1.98,3.27-0.39,6.78-2.35,10.05c-1.32,2.21-0.96,5.75-1.64,8.72c-1.58,6.89-3.12,13.86-4.22,20.79
			c-1.05,6.61-2.61,13.17-3.51,19.84c-0.77,5.74-1.56,11.73-3.73,17.32c-1.07,2.77-0.72,5.84-1.49,8.81
			c-1.08,4.14-2.08,8.47-2.62,12.79c-0.55,4.36-1.57,8.65-2.28,12.97c-0.8,4.94-2.05,9.84-2.9,14.82c-0.8,4.7-2.43,9.29-2.71,14.13
			c-2.98,2.48-0.72,6.21-2.28,9.28c-1.49,2.95-1.5,6.66-2.08,10.05c-0.55,3.19-0.8,6.44-1.51,9.59c-1.66,7.37-3.04,14.82-4.62,22.18
			c-1.23,5.71-2.46,11.57-3.72,17.28c-1.33,6-2.34,12.26-3.55,18.33c-0.89,4.45-1.04,9.04-2.44,13.48
			c-1.35,4.28-2.35,8.91-2.86,13.33c-0.72,6.24-2.05,12.37-3.41,18.38c-1.9,8.43-3.29,16.98-5.28,25.4
			c-1.36,5.73-2.18,11.79-3.37,17.63c-1.73,8.48-3.29,17-5.23,25.45c-1.33,5.77-2.3,11.72-3.43,17.58
			c-1.69,8.73-3.14,17.51-5.38,26.15c-0.79,3.05-1.15,6.31-1.79,9.47c-0.68,3.4-0.08,7.44-1.74,10.17
			c-1.98,3.25,0.42,7.26-2.67,9.87c0.38,3.56-0.49,6.92-1.38,10.35c-0.86,3.35-0.09,7.47-1.78,10.15
			c-2.06,3.27-0.35,6.79-2.35,10.06c-1.32,2.15-0.8,5.75-1.65,8.72c-1.01,3.54-0.13,7.89-1.84,10.87c-1.88,3.28,0.35,7.24-2.66,9.87
			c0.26,6.35-1.99,12.34-3.18,18.41c-1.16,5.97-2.56,12.03-3.77,17.98c-1.23,6.04-2.12,12.34-3.5,18.34
			c-1.93,8.43-2.55,17.13-5.47,25.37c-0.81,2.29,0.39,5.19-1.57,7.37c0.35,5.98-3.66,9.87-7.11,13.78
			c-4.8,5.43-9.82,10.65-17.14,13.07c-3.5,1.16-6.78,2.53-10.59,2.62c-7.32,0.17-14.49-0.27-21.39-2.97
			c-3.4-1.33-6.53-2.96-9.55-5.15c-9.56-6.92-18.33-14.87-28.28-21.32c-5.47-3.54-10.11-8.34-15.39-12.2
			c-5.02-3.67-10.04-7.37-15-11.09c-4.83-3.62-9.47-7.56-14.37-11.17c-10.08-7.42-19.6-15.58-29.93-22.72
			c-7.28-5.03-13.87-11.03-20.95-16.36c-9.42-7.1-18.67-14.43-28.22-21.38c-5.24-3.81-10.23-8.21-15.5-12.06
			c-9.54-6.95-18.32-14.88-28.29-21.3c-5.28-3.4-9.83-7.9-14.85-11.72c-5.14-3.91-10.49-7.61-15.52-11.58
			c-5.13-4.05-10.26-8.15-15.47-12.12c-7.55-5.75-14.61-12.15-22.8-17.05c-0.61-0.36-1.02-1.03-1.56-1.52
			c-3.67-3.34-3.6-4.13,0.68-6.96c0.95-0.63,2.06-1.06,2.88-1.82c7.56-7,15.04-14.1,22.62-21.08c3.3-3.04,6.78-5.87,10.2-8.77
			c3.24-2.75,5.44-6.69,9.69-8.27c0.41-0.15,0.69-0.67,1.04-1c7.7-7.22,14.92-15.05,23.24-21.45c6.98-5.36,12.03-12.81,19.76-17.21
			c0.41-0.23,0.69-0.68,1.04-1.01c7.7-7.22,15.2-14.66,23.15-21.58c9.88-8.61,19.55-17.42,29.31-26.14
			c4.42-3.96,8.69-8.1,13.15-12.03c3.18-2.81,6.42-5.55,9.7-8.24c3.09-2.54,4.99-6.48,9.17-7.77c0.41-0.13,0.69-0.67,1.04-1
			c7.7-7.22,15.38-14.45,23.1-21.63c5.95-5.53,11.61-11.46,18.05-16.34c4.61-3.5,8.08-8.22,12.77-11.36
			c7.37-4.92,12.09-12.75,19.82-17.15c0.41-0.23,0.69-0.68,1.04-1.01c7.7-7.22,15.36-14.47,23.11-21.62
			c3.12-2.87,6.45-5.52,9.68-8.26c3.24-2.74,5.44-6.7,9.69-8.27c0.41-0.15,0.69-0.67,1.04-1c8.04-7.56,16.05-15.15,24.14-22.66
			c5.06-4.7,10.15-9.38,15.39-13.88c2.76-2.38,4.15-5.54,5.57-8.65c1.91-4.15-0.78-8.28-5.23-8.49c-6.43-0.3-11.94,1.5-17.68,4.9
			c-12.51,7.42-24.51,15.63-36.95,23.16c-8.95,5.42-17.8,11.22-26.47,17.19c-6.74,4.64-13.94,8.59-20.79,13.08
			c-13.17,8.62-26.47,17.07-39.81,25.41c-12.31,7.7-24.24,16.04-37.03,23.02c-7.8,4.26-14.64,10.27-22.36,14.71
			c-12.62,7.25-24.62,15.48-37.01,23.08c-8.73,5.35-17.31,11.09-25.97,16.62c-7.28,4.65-14.3,9.68-21.92,13.85
			c-7.38,4.03-14.22,9.06-21.38,13.51c-7.53,4.68-14.99,9.52-22.44,14.3c-8.84,5.67-17.69,11.37-26.54,17.04
			c-7.07,4.53-13.98,9.34-21.35,13.45c-7.55,4.21-14.53,9.45-21.94,13.93c-7.4,4.47-14.17,9.89-21.98,13.75
			c-3.66,1.81-6.81,4.64-10.28,6.88c-1.89,1.22-4.12,1.95-5.9,3.29c-2.38,1.79-4.29,1.52-7.08,0.53
			c-16.33-5.76-32.8-11.12-49.23-16.62c-6.19-2.07-12.39-4.09-18.57-6.19c-5.96-2.02-11.89-4.14-17.85-6.17
			c-13.62-4.64-27.23-9.34-40.89-13.85c-10.69-3.53-21.8-6.04-32.1-10.45c-10.32-4.41-20.15-10.2-25.26-21.32
			c-0.77-1.67-0.66-3.12-0.87-4.69c-0.55-1.43,1.35-3.69-1.46-4.37c0-0.49,0-0.97,0-1.46C144.56,502.01,142.65,499.76,143.21,498.33
			z"
          />
          <path
            className="st0"
            d="M143.21,498.33c-0.55,1.43,1.35,3.69-1.46,4.37c0-1.46,0-2.91,0-4.37
			C142.24,498.33,142.72,498.33,143.21,498.33z"
          />
          <path
            className="st0"
            d="M141.75,504.15c2.81,0.68,0.9,2.94,1.46,4.37c-0.49,0-0.97,0-1.46,0
			C141.75,507.07,141.75,505.61,141.75,504.15z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconTelegram;
