const IconX = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      style={{ fill: 'white', height: '20px', width: '18px' }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            className="st0"
            d="M130.82,967.15c-25.79,0-51.59,0-77.38,0c1.49-2.77,2.54-5.93,4.54-8.26
			c93.96-109.37,188.01-218.67,282.04-327.98c20.9-24.3,41.78-48.61,62.61-72.85c-1.3-1.97-2.26-3.49-3.28-4.97
			C293.39,398.85,187.41,244.6,81.49,90.32c-10.13-14.76-19.9-29.76-29.84-44.65c89.38,0,178.75,0,268.13,0
			c0.87,1.55,1.62,3.17,2.62,4.63c75.28,109.61,150.58,219.21,225.91,328.79c1.13,1.64,2.59,3.05,4.03,4.74
			c1.37-1.51,2.38-2.58,3.35-3.71c94.25-109.55,188.5-219.11,282.71-328.7c1.41-1.64,2.18-3.82,3.25-5.75c26.39,0,52.79,0,79.18,0
			c-1.08,2.06-1.8,4.41-3.28,6.13c-81.05,94.35-162.16,188.65-243.27,282.96c-28.89,33.6-57.78,67.2-86.85,101.01
			c1.51,2.31,2.77,4.32,4.11,6.27C694.45,591.82,797.39,741.58,900.25,891.4c17.25,25.12,34.12,50.5,51.16,75.76
			c-89.38,0-178.75,0-268.13,0c-0.87-1.55-1.61-3.17-2.61-4.63c-79.49-115.75-159-231.48-238.53-347.2
			c-1.25-1.83-2.85-3.42-4.46-5.33c-3.04,3.45-5.64,6.34-8.18,9.29c-60.59,70.45-121.19,140.87-181.73,211.36
			C208.73,876.11,169.8,921.65,130.82,967.15z M159.33,105.4c1.44,2.44,1.98,3.51,2.66,4.48
			c185.12,264.84,370.23,529.68,555.57,794.37c2.06,2.95,7.08,5.36,10.75,5.4c35.68,0.4,71.36,0.25,107.04,0.21
			c2.16,0,4.31-0.43,7.31-0.75c-1.61-2.39-2.57-3.85-3.57-5.29C716.78,728.81,594.46,553.79,472.14,378.79
			c-62.11-88.85-124.21-177.71-186.45-266.46c-2-2.85-5.49-6.6-8.33-6.64C238.47,105.25,199.58,105.4,159.33,105.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconX;
