const IconEagle = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      style={{ fill: 'white', height: '20px', width: '23px' }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            className="st0"
            d="M482.68,894.98C443.89,832.09,405.86,770.44,367.35,708c-24.25,22.75-47.77,44.83-72.2,67.74
			c-23.02-37.29-45.54-73.77-68.71-111.31c-31.45,25.3-62.4,50.21-95.32,76.7c8.7-19.75,16.9-36.93,23.94-54.59
			c31.16-78.14,43.26-159.7,43.08-243.45c-0.08-37.86-0.48-75.77,1.12-113.59c1.72-40.87,14.93-78.88,34-114.89
			c0.76-1.44,1.92-2.67,2.88-3.98c15.05,17.63,29.93,34.64,44.1,52.22c1.62,2.01,0.19,7.67-1.37,10.88
			c-9.78,20.01-14.83,40.77-10.73,63.01c7.34,39.79,43.98,70.27,88.59,74.02c5.09,0.43,10.2,0.72,15.84,1.11
			c-0.83,13.22-1.22,25.88-2.75,38.39c-0.34,2.78-4.34,5.72-7.32,7.46c-24.92,14.64-50,29.02-75.02,43.49
			c-2.34,1.35-4.59,2.84-8.48,5.26c15.62,8.75,29.77,17.55,44.66,24.85c31.03,15.22,57.74,34.97,73.37,66.79
			c10.14,20.67,21.49,41.03,28.87,62.7c18.38,54.02,34.81,108.71,52.02,163.13c1.32,4.18,2.68,8.36,4.93,15.4
			c9.16-31.13,17.36-59.38,25.8-87.55c15-50.13,30.92-99.91,55.57-146.4c15.35-28.95,35.16-53,65.69-67.5
			c18.88-8.96,36.63-20.28,56.56-31.48c-9.13-5.43-16.92-10.18-24.82-14.75c-19.65-11.38-39.23-22.89-59.1-33.89
			c-5.58-3.09-7.47-6.75-7.57-13.01c-0.17-10.6-1.55-21.18-2.47-32.32c9.88-1.29,19.78-1.9,29.38-3.95
			c41.93-8.97,70.74-38.09,76.08-76.9c2.71-19.72-1.51-38.54-10.92-55.82c-4.45-8.16-2.85-12.92,3.14-19.42
			c13.47-14.62,25.8-30.29,39.19-46.24c14.89,25.68,25.91,52.66,31.22,81.46c3.72,20.2,5.82,40.91,6.4,61.45
			c1.31,45.76,0.13,91.59,1.93,137.32c3.33,84.64,23.68,165.24,61.44,241.26c1.02,2.06,1.86,4.22,1.42,7.51
			c-30.6-24.63-61.2-49.26-92.81-74.71c-22.81,36.93-45.32,73.37-68.71,111.24c-24.16-22.61-47.81-44.75-72.29-67.65
			C559.45,770.48,521.44,832.12,482.68,894.98z"
          />
          <path
            className="st0"
            d="M212.75,103.51c14.17,10.24,28.94,22.59,45.29,32.25c15.15,8.95,18.91,7.21,31.47-4.69
			c107.11-101.52,281.56-100.96,387.7,1.72c8.33,8.06,16.41,10.51,25.46,5.03c13.98-8.47,27.17-18.29,40.45-27.87
			c3.18-2.3,5.41-5.92,8.08-8.94c0.75,0.48,1.51,0.96,2.26,1.44c-3.69,7.96-6.98,16.12-11.12,23.84
			c-19.1,35.57-45.33,65.56-74.29,93.27c-38.74,37.06-81.2,69.05-128.91,93.86c-5.67,2.95-9.81,3.31-15.79-0.35
			c-27.09-16.62-54.68-16.64-81.78,0.25c-4.93,3.08-8.68,3.76-13.99,0.88c-78.01-42.34-144.92-97.3-194.83-171.47
			c-6.87-10.21-11.87-21.68-17.55-32.67C213.67,107.1,212.85,103.79,212.75,103.51z"
          />
          <path
            className="st0"
            d="M372.76,505.17c10.07-5.81,18.99-11.72,28.55-16.28c11.42-5.44,15.85-13.28,15.18-26.18
			c-1.19-23.11,0.88-46.06,9.45-67.99c6.05-15.49,15.8-28.12,28.89-38.2c17.65-13.58,37.34-13.67,55.19-0.35
			c25.41,18.96,35.12,45.88,37.93,76.04c0.99,10.6,1.59,21.37,0.81,31.95c-0.86,11.67,3.55,18.7,13.84,23.75
			c10.04,4.93,19.43,11.2,30.59,17.76c-69.22,40.34-87.57,110.35-110.31,180.81c-2.1-5.86-3.34-9.45-4.67-13.01
			c-10.67-28.65-19.82-57.99-32.41-85.76C429.09,550.8,416.45,538.38,372.76,505.17z"
          />
          <path
            className="st0"
            d="M407.18,354.84c-23.04,18.54-70.17,12.48-87.58-11.91c-9.06-12.69-9.11-30.06-0.55-43.73
			C348.51,317.79,377.97,336.4,407.18,354.84z"
          />
          <path
            className="st0"
            d="M646.72,298.98c12.6,25.52,3.98,49.28-22.36,60.6c-21.58,9.28-48.9,7.27-66.49-4.59
			C587.14,336.54,616.62,317.95,646.72,298.98z"
          />
        </g>
      </g>
    </svg>
  );
};
export default IconEagle;
