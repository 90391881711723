const IconCube = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 220 256"
      className="header__logo"
      style={{ height: '23px', width: '25px' }}
    >
      <g strokeLinejoin="bevel" strokeWidth="2">
        <path
          fill="#CC7400"
          stroke="#CC7400"
          d="m110 128 27.25-15.75v31.5L110 159.5z"
        ></path>
        <path
          fill="#DE7F00"
          stroke="#DE7F00"
          d="m110 222.5 27.25-15.75v31.5L110 254z"
        ></path>
        <path
          fill="#D17200"
          stroke="#D17200"
          d="m110 191 27.25-15.75v31.5L110 222.5z"
        ></path>
        <path
          fill="#E38900"
          stroke="#E38900"
          d="m110 159.5 27.25-15.75v31.5L110 191z"
        ></path>
        <path
          fill="#C97900"
          stroke="#C97900"
          d="M191.75 80.75 219 65v31.5l-27.25 15.75z"
        ></path>
        <path
          fill="#C96E00"
          stroke="#C96E00"
          d="M191.75 175.25 219 159.5V191l-27.25 15.75z"
        ></path>
        <path
          fill="#CC7400"
          stroke="#CC7400"
          d="M191.75 143.75 219 128v31.5l-27.25 15.75z"
        ></path>
        <path
          fill="#E68A00"
          stroke="#E68A00"
          d="M191.75 112.25 219 96.5V128l-27.25 15.75zM164.5 96.5l27.25-15.75v31.5L164.5 128z"
        ></path>
        <path
          fill="#D68100"
          stroke="#D68100"
          d="m164.5 191 27.25-15.75v31.5L164.5 222.5z"
        ></path>
        <path
          fill="#C96E00"
          stroke="#C96E00"
          d="m164.5 159.5 27.25-15.75v31.5L164.5 191z"
        ></path>
        <path
          fill="#DB7D00"
          stroke="#DB7D00"
          d="m164.5 128 27.25-15.75v31.5L164.5 159.5z"
        ></path>
        <path
          fill="#D18100"
          stroke="#D18100"
          d="M137.25 112.25 164.5 96.5V128l-27.25 15.75z"
        ></path>
        <path
          fill="#C47000"
          stroke="#C47000"
          d="M137.25 206.75 164.5 191v31.5l-27.25 15.75z"
        ></path>
        <path
          fill="#D17E00"
          stroke="#D17E00"
          d="m137.25 175.25 27.25-15.75V191l-27.25 15.75z"
        ></path>
        <path
          fill="#CC7400"
          stroke="#CC7400"
          d="M137.25 143.75 164.5 128v31.5l-27.25 15.75z"
        ></path>
        <path
          fill="#FC9800"
          stroke="#FC9800"
          d="m1.003 65 27.25 15.75v31.5L1.003 96.5zM1.003 159.5l27.25 15.75v31.5L1.003 191z"
        ></path>
        <path
          fill="#FA0"
          stroke="#FA0"
          d="m1.003 128 27.25 15.75v31.5L1.003 159.5z"
        ></path>
        <path
          fill="#FFBD1E"
          stroke="#FFBD1E"
          d="m1.003 96.5 27.25 15.75v31.5L1.003 128z"
        ></path>
        <path
          fill="#FC9800"
          stroke="#FC9800"
          d="m82.753 112.25 27.25 15.75v31.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FA0"
          stroke="#FA0"
          d="m82.753 206.75 27.25 15.75V254l-27.25-15.75z"
        ></path>
        <path
          fill="#FA0"
          stroke="#FA0"
          d="m82.753 175.25 27.25 15.75v31.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FA0"
          stroke="#FA0"
          d="m82.753 143.75 27.25 15.75V191l-27.25-15.75zM55.503 96.5l27.25 15.75v31.5L55.503 128z"
        ></path>
        <path
          fill="#FC9800"
          stroke="#FC9800"
          d="m55.503 191 27.25 15.75v31.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FFBD1E"
          stroke="#FFBD1E"
          d="m55.503 159.5 27.25 15.75v31.5L55.503 191z"
        ></path>
        <path
          fill="#FFB304"
          stroke="#FFB304"
          d="m55.503 128 27.25 15.75v31.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FA0"
          stroke="#FA0"
          d="m28.253 80.75 27.25 15.75V128l-27.25-15.75z"
        ></path>
        <path
          fill="#FFB304"
          stroke="#FFB304"
          d="M28.253 175.25 55.503 191v31.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FA0"
          stroke="#FA0"
          d="m28.253 143.75 27.25 15.75V191l-27.25-15.75z"
        ></path>
        <path
          fill="#FC9800"
          stroke="#FC9800"
          d="M28.253 112.25 55.503 128v31.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FFE929"
          stroke="#FFE929"
          d="m110 2 27.25 15.75L110 33.5 82.75 17.75zM28.25 49.25 55.5 65 28.25 80.75 1 65z"
        ></path>
        <path
          fill="#FFE65E"
          stroke="#FFE65E"
          d="m55.5 33.5 27.25 15.75L55.5 65 28.25 49.25z"
        ></path>
        <path
          fill="#FFDB2E"
          stroke="#FFDB2E"
          d="M82.75 17.75 110 33.5 82.75 49.25 55.5 33.5zM191.75 49.25 219 65l-27.25 15.75L164.5 65z"
        ></path>
        <path
          fill="#FFE65E"
          stroke="#FFE65E"
          d="m110 96.5 27.25 15.75L110 128l-27.25-15.75z"
        ></path>
        <path
          fill="#FFDB2E"
          stroke="#FFDB2E"
          d="M137.25 80.75 164.5 96.5l-27.25 15.75L110 96.5z"
        ></path>
        <path
          fill="#FFE65E"
          stroke="#FFE65E"
          d="m164.5 65 27.25 15.75L164.5 96.5l-27.25-15.75z"
        ></path>
        <path
          fill="#FFE36A"
          stroke="#FFE36A"
          d="m164.5 33.5 27.25 15.75L164.5 65l-27.25-15.75z"
        ></path>
        <path
          fill="#FFDB2E"
          stroke="#FFDB2E"
          d="M82.75 80.75 110 96.5l-27.25 15.75L55.5 96.5z"
        ></path>
        <path
          fill="#FFE36A"
          stroke="#FFE36A"
          d="m110 65 27.25 15.75L110 96.5 82.75 80.75z"
        ></path>
        <path
          fill="#FFDB2E"
          stroke="#FFDB2E"
          d="M137.25 49.25 164.5 65l-27.25 15.75L110 65z"
        ></path>
        <path
          fill="#FFE65E"
          stroke="#FFE65E"
          d="M137.25 17.75 164.5 33.5l-27.25 15.75L110 33.5z"
        ></path>
        <path
          fill="#FFE929"
          stroke="#FFE929"
          d="m55.5 65 27.25 15.75L55.5 96.5 28.25 80.75z"
        ></path>
        <path
          fill="#FFE65E"
          stroke="#FFE65E"
          d="M82.75 49.25 110 65 82.75 80.75 55.5 65z"
        ></path>
        <path
          fill="#FFE929"
          stroke="#FFE929"
          d="m110 33.5 27.25 15.75L110 65 82.75 49.25z"
        ></path>
      </g>
    </svg>
  );
};
export default IconCube;
